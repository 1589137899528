<template>
  <b-card class="card-widget">
    <b-card-header class="pl-0 pr-0 ml-0 mr-0">
      <h4 class="card-title">Details Penerimaan</h4>
      <b-button v-if="showActionButton === true" variant="primary" :to="{ name: 'penerimaan-edit', params: { laporan_id: rowData.data.id } }"><feather-icon icon="EditIcon" /> Ubah</b-button>
      <!-- <b-row>
        
        <b-col cols="8">
          <h4 class="card-title">Details Penerimaan</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-link title="Edit" :to="{ name: 'penerimaan-edit', params: { laporan_id: rowData.data.id } }"  class="me-1 mr-1 btn btn-primary btn-sm"><feather-icon icon="EditIcon" /> Edit Penerimaan</b-link>
        
        </b-col>
        
      </b-row> -->
    </b-card-header>
    <div class="row">
      <div class="col-xl-6 col-12">
        <dl class="row mb-0">
          <dt class="col-sm-12 fw-bolder">Nomor Penerimaan
          </dt>
          <dd class="col-sm-12 mb-1">
            <div v-if="rowData.data.no_penerimaan && rowData.data.status">
              <b-badge pill :variant="`light-${rowData.data.status.class}`">{{ rowData.data.no_penerimaan }}</b-badge>
            </div>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-12 fw-bolder" >Tanggal Penerimaan
          </dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.tgl_penerimaan">{{ rowData.data.tgl_penerimaan }}</span>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-12 fw-bolder">Nomor Register</dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.no_register" style="background-color: yellow;">{{ rowData.data.no_register }}</span>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-12 fw-bolder">Tanggal Register
          </dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.tgl_register">{{ rowData.data.tgl_register }}</span>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-12 fw-bolder">Nomor Surat</dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.no_surat">{{ rowData.data.no_surat }}</span>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-12 fw-bolder">Tanggal Surat</dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.tgl_surat">{{ rowData.data.tgl_surat }}</span>
            <span v-else>-</span>
          </dd>

          <dt class="col-sm-6 fw-bolder">Perihal :</dt>
          <dd class="col-sm-12 mb-1"><span v-html="rowData.data.perihal_data"></span></dd>
        </dl>
      </div>
      <div class="col-xl-6 col-12">
        <dl class="row mb-0">
          <dt class="col-sm-12 fw-bolder">Jenis Penyampaian
          </dt>
          <dd class="col-sm-12 mb-1"><span v-if="rowData.data.jenis_penyampaian">{{ rowData.data.jenis_penyampaian.nama }}</span></dd>

          <dt class="col-sm-12 fw-bolder">Diterima Melalui
          </dt>
          <dd class="col-sm-12 mb-1">
            <span v-if="rowData.data.kantor_penghubung">{{ rowData.data.kantor_penghubung.nama }}</span>
            <span v-else>{{ rowData.data.diterima_melalui.nama }}</span>
          </dd>

          <dt class="col-sm-12 fw-bolder">Jenis Perkara</dt>
          <dd class="col-sm-12 mb-1"><span v-if="rowData.data.jenis_perkara">{{ rowData.data.jenis_perkara.nama }}</span></dd>

          <dt class="col-sm-12 fw-bolder">Lingkungan Peradilan
          </dt>
          <dd class="col-sm-12 mb-1"><span v-if="rowData.data.lingkungan_peradilan">{{ rowData.data.lingkungan_peradilan.nama }}</span></dd>

          <dt class="col-sm-12 fw-bolder">Lokasi Laporan</dt>
          <dd class="col-sm-12 mb-1"><span v-if="rowData.data.kota">{{ rowData.data.kota.nama_kota }}</span><span v-else>-</span></dd>

          <dt class="col-sm-6 fw-bolder">Nomor Putusan :</dt>
          <dd class="col-sm-12 mb-1">
            <!-- <div  
              v-for="dataPutusan in rowData.data.putusan"
              :key="dataPutusan.id"
            >
              {{ dataPutusan.badan_peradilan }} :
              {{ dataPutusan.no_putusan}}
            </div> -->
            <table>
            
                <tr>
                  <td>
                    - Putusan Pengadilan Tingkat Pertama
                  </td>
                  <td>
                    :
                  </td>
                  <td style="padding-left: 10px;">
                    <span v-if="rowData.data.putusan_pengadilan_tk_1">
    
                        {{ rowData.data.putusan_pengadilan_tk_1 }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                </tr>
        
             
      
        
                <tr>
                  <td>
                    - Putusan Pengadilan Tingkat Banding
                  </td>
                  <td>
                    :
                  </td>
                  <td style="padding-left: 10px;">
                    <span v-if="rowData.data.putusan_pengadilan_tk_2">
    
                        {{ rowData.data.putusan_pengadilan_tk_2 }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                </tr>
           
             
            
       
                <tr>
                  <td>
                    - Putusan Pengadilan Tingkat Kasasi
                  </td>
                  <td>
                    :
                  </td>
                  <td style="padding-left: 10px;">
                    <span v-if="rowData.data.putusan_pengadilan_tk_3">
    
                        {{ rowData.data.putusan_pengadilan_tk_3 }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                </tr>
                
             
            </table>
        
          </dd> 
          <!-- <dt class="col-sm-12 fw-bolder">Keterangan :</dt>
          <dd class="col-sm-12 mb-1"><button type="reset" class="btn btn-sm btn-success">Lihat
              Data</button></dd>-->
        </dl>
      </div>
      <!-- <div class="col-12 mt-3">
        <b-col cols="2" class="text-right">
          <b-link  title="Teruskan ke Verifikasi" v-b-modal="`modal-toVerif-${rowData.data.id}`" class="me-1 mr-1 btn btn-primary btn-sm">Teruskan ke Verifikasi</b-link>
          <div>
            <b-modal :id="`modal-toVerif-${rowData.data.id}`" centered title="Konfirmasi" @ok="toVerifData(rowData.data.id)">
              <div class="d-flex justify-content-center">
                <h4 class="my-4">Apakah anda yakin untuk meneruskan data ini ke proses verifikasi?</h4>
              </div>
            </b-modal>
          </div>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-link  title="Arsipkan Penerimaan" v-b-modal="`modal-toArsip-${rowData.data.id}`" class="me-1 mr-1 btn btn-primary btn-sm">Arsipkan Penerimaan</b-link>
          <div>
            <b-modal :id="`modal-toArsip-${rowData.data.id}`" centered title="Konfirmasi" @ok="toArsip(rowData.data.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin untuk mengarsipkan penerimaan ini?</h4>
                  </div>
            </b-modal>
          </div>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-link  title="Teruskan ke Biro Lain" v-b-modal="`modal-toBiroLain-${rowData.data.id}`" class="me-1 mr-1 btn btn-primary btn-sm">Teruskan ke Biro Lain</b-link>
          <div>
            <b-modal :id="`modal-toBiroLain-${rowData.data.id}`" centered title="Konfirmasi" @ok="toBiroLain(rowData.data.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin untuk meneruskan penerimaan ini ke biro lain?</h4>
                  </div>
            </b-modal>
          </div>
        </b-col> -->
        <!-- <hr>
        <div class="row mt-5">
          <div class="d-grid col-lg-4 col-md-12 mb-1 mb-lg-0">
            <button type="button" class="btn btn-outline-primary">
              <i data-feather="external-link" class="me-25"></i>
              <span>Teruskan ke Verifikasi</span></button>
          </div>
          <div class="d-grid col-lg-4 col-md-12">
            <button type="button" class="btn btn-outline-primary"><i data-feather="archive"
                class="me-25"></i>
              <span>Arsipkan</span></button>
          </div>
          <div class="d-grid col-lg-4 col-md-12">
            <button type="button" class="btn btn-outline-primary"><i
                data-feather="corner-up-right" class="me-25"></i>
              <span>Teruskan
                ke Biro lain</span></button>
          </div> -->
        <!-- </div>
      </div> -->
      <div class="col-12 mt-3">
        <!-- <div class="d-flex mt-2" v-else>
          <b-button class="btn mr-1" variant="outline-primary" v-b-modal.modal_confirm_1>Laporan Diregister</b-button>
          <b-button class="btn mr-1" variant="outline-primary" v-b-modal.modal_confirm_2>Arsipkan</b-button>
          <b-button class="btn mr-1" variant="outline-primary" v-b-modal.modal_confirm_3>Teruskan ke Pemantauan</b-button>
        </div> -->
        <div class="d-flex mt-2"
          v-if="rowData.data.status_laporan_id != 25 && rowData.data.no_penerimaan != null"
        >
          <b-button v-if="showActionButton === true" class="btn mr-1" variant="primary outline-primary" title="Teruskan ke Verifikasi" v-b-modal="`modal-toVerif-${rowData.data.id}`">Teruskan ke Verifikasi</b-button>
          <b-button v-if="showActionButton === true" class="btn mr-1" variant="primary outline-primary" title="Arsipkan Penerimaan" v-b-modal="`modal-toArsip-${rowData.data.id}`" >Arsipkan Penerimaan</b-button>
          <b-button class="btn mr-1" variant="outline-primary" title="Tanda Terima" @click="downloadTandaTerima()" >Tanda Terima</b-button>
          <b-button class="btn mr-1" variant="outline-primary"  title="Resume Penerimaan" @click="downloadResume()" >Resume Penerimaan</b-button>
        </div>
        <div class="d-flex mt-2"
          v-if="rowData.data.status_laporan_id != 25 && rowData.data.no_penerimaan == null"
        >
          <b-button class="btn mr-1" variant="outline-primary" title="Tanda Terima" @click="generateNomorPenerimaan()" >Generate Nomor Penerimaan</b-button>
        </div>  
        <div>
          <b-modal :id="`modal-toVerif-${rowData.data.id}`" centered title="Konfirmasi" @ok="update('toVerif')">
            <div class="d-flex justify-content-center">
              <h4 class="my-4">Apakah anda yakin untuk meneruskan data ini ke proses verifikasi?</h4>
            </div>
          </b-modal>
        </div>
        <div>
          <b-modal :id="`modal-toArsip-${rowData.data.id}`" centered title="Konfirmasi" @ok="update('toArsip')">
            <div class="d-flex justify-content-center">
              <h4 class="my-4">Apakah anda yakin untuk mengarsipkan penerimaan ini?</h4>
            </div>
          </b-modal>
        </div>
        <!-- <div>
          <b-modal :id="`modal-toBiroLain-${rowData.data.id}`" centered title="Konfirmasi" @ok="update('toBiroLain')">
            <div class="d-flex justify-content-center">
              <h4 class="my-4">Apakah anda yakin untuk meneruskan penerimaan ini ke biro lain?</h4>
            </div>
          </b-modal>
        </div> -->
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BLink, BCardHeader
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useList from '@/connection-api/laporan/list'
import moment from 'moment'
import {
  updatePenerimaan,
  downloadTemplate,
  downloadTemplatePdf,
} from '@/connection-api/master'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      showActionButton: true,
    }
  },
  mounted() {
    // console.log(this.rowData);
    if(this.rowData.data.status && this.rowData.data.status.nama === 'Arsip')
      this.showActionButton = false
  },
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BLink, BCardHeader
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useList()
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
    }
  },
  
  methods: {
    async generateNomorPenerimaan() {
      this.rowData.data.id = this.$route.params.laporan_id
      // console.log(this.rowData.data.no_penerimaan)
      this.rowData.data.generate_no_penerimaan = true
      const response = await updatePenerimaan(this.rowData.data)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Penerimaan',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Berhasil generate nomor penerimaan!',
        },
      })
      this.rowData.data.no_penerimaan = response.data.data
    },
    async downloadTandaTerima() {
      const jenis_dokumen_pengesahan_id = 73
      const result = await downloadTemplatePdf(jenis_dokumen_pengesahan_id, this.rowData.data.id)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', jenis_dokumen_pengesahan_id + '_' + this.rowData.data.id + 'Tanda-Terima.pdf')
      document.body.appendChild(link)
      link.click()
    },
    async downloadResume() {
      const jenis_dokumen_pengesahan_id = 78
      const result = await downloadTemplate(jenis_dokumen_pengesahan_id, this.rowData.data.id)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', jenis_dokumen_pengesahan_id+'_'+ this.rowData.data.id +'_Resume-Penerimaan.docx')
      document.body.appendChild(link)
      link.click()
    },
    async update(params) {
      if(this.rowData.data.no_penerimaan != null){
        switch (params) {
        case 'toVerif':
          this.rowData.data.jenis_cta = "toVerif";
          break;
        case 'toArsip':
          this.rowData.data.jenis_cta = 'toArsip';
          break;
        case 'toBiroLain':
           this.rowData.data.jenis_cta = 'toBiroLain';
          break;
      }
      
      this.rowData.data.id = this.$route.params.laporan_id
      // console.log(this.rowData.data.no_penerimaan)
      const response = await updatePenerimaan(this.rowData.data)
      if(response.data.success === true)
          {
            if(params == 'toVerif'){
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Penerimaan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Berhasil meneruskan ke verifikasi!',
                },
              })
            } else if(params == 'toArsip'){
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Penerimaan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Berhasil mengarsipkan penerimaan!',
                },
              })
            } else{
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Penerimaan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Berhasil meneruskan ke biro!',
                },
              })
            }
            
              this.$emit('refetchData')
              this.$router.push({name: 'verifikasi'})
              this.close()
          }
          else if(response.data.success === false)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Penerimaan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
      }else if(this.rowData.data.no_penerimaan == null){
        // console.log(this.rowData.data.no_penerimaan)
        this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Penerimaan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Gagal, nomor penerimaan belum digenerate!',
                },
              })
      }
      
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(value, format) {
      return moment(String(value)).format(format)
    },
    formatDateComplete (value, toTimeForCurrentDay = true) {
      if (value !== '') {
        return '-'
      } else {
        const date = new Date(value)
        let formatting = { dateStyle: 'medium', timeZone: 'Asia/Jakarta' }

        return new Intl.DateTimeFormat('id-ID', formatting).format(new Date(value))
      }
    },
    async toVerifData(id) {
        const data = {
        laporan_id: id
        }
        const resp = await toVerifPenerimaan(data)
        if(resp.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ke Proses Verifikasi',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil diteruskan ke proses verifikasi',
              },
            })
            this.refetchData()
        }
    },
    async toArsip(id) {
        const data = {
        laporan_id: id
        }
        const resp = await toArsip(data)
        if(resp.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Arsipkan Penerimaan',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil diarsipkan',
              },
            })
            this.refetchData()
        }
    },
  },
}
</script>

<style>

  

</style>
