<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="rowData === undefined"
    >
      <h4 class="alert-heading">
        Gagal menemukan data
      </h4>
      <div class="alert-body">
        Tidak ada data ditemukan, Cek
        <b-link         class="alert-link"
          :to="{ name: 'monitoring-list'}"
        >
          Disini
        </b-link>
        untuk melihat data lainnya.
      </div>
    </b-alert>

    <template v-if="rowData">
      <b-row>
        <b-col
          cols="4"
          md="4"
        >
          <status-timeline-card :log-data="rowData.data.log_laporans" />
        </b-col>
        <b-col
          cols="8"
          md="8"
          v-if="rowData.data"
        >
          <view-info-card :row-data="rowData" />
        </b-col>
      </b-row>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <!-- <top-form :localData="localData"/> -->
          <b-tabs
            content-class="col-12 col-md-12 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-12 col-12"
            nav-class="nav-left"
          >
            <!-- Pelapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pelapor</span>
              </template>
              <pelapor :buttonOff=true />
            </b-tab>
            <!--/ Pelapor -->
            <!-- Kuasa Pelapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kuasa Pelapor</span>
              </template>
              <kuasa-pelapor :buttonOff=true />
            </b-tab>
            <!--/ Kuasa Pelapor -->
            <!-- Terlapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Terlapor</span>
              </template>
              <terlapor :buttonOff=true />
            </b-tab>
            <!--/ Terlapor -->
             <!-- Kelengkapan Data -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kelengkapan Data</span>
              </template>
              <kelengkapan-data :buttonOff=true />
            </b-tab>
            <!--/ Kelengkapan Data -->
          <!-- Surat -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Surat</span>
              </template>
              <surat/>
            </b-tab> -->
            <!--/ Surat -->




            <!-- Dokumen -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen</span>
              </template>
              <dokumen />
            </b-tab> -->
            <!--/ Dokumen -->




            <!-- Berita Acara -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Berita Acara</span>
              </template>
             <berita-acara />
            </b-tab> -->
            <!--/ Berita Acara -->




            <!-- Dokumen MKH -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen MKH</span>
              </template>
            </b-tab> -->
            <!--/ Dokumen MKH -->




            <!-- Pemantauan -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pemantauan</span>
              </template>
              <terlapor/>
            </b-tab> -->
            <!--/ Pemantauan -->




            <!-- Logs -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Logs</span>
              </template>
            </b-tab> -->
            <!--/ Logs -->





          </b-tabs>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>
      </b-row> -->

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTabs, BTab,
} from 'bootstrap-vue'
import {
  getAllJenisDokumen,
} from '@/connection-api/master'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import storeModule from '@/connection-api/laporan/storeModule'
import StatusTimelineCard from './StatusTimelineCard.vue'
import ViewInfoCard from './ViewInfoCard.vue'
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KelengkapanData from '@/views/kelengkapan-data/list/List.vue'
import Surat from '@/views/surat/list/List.vue'
import Dokumen from '@/views/dokumen/list/List.vue'
import BeritaAcara from '@/views/berita-acara/list/List.vue'
// import ViewDokumenLainnya from '../element/ViewDokumenLainnya.vue'
// import ViewDokumenFoto from '../element/ViewDokumenFoto.vue'
// import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    // BLink,
    BTabs,
    BTab,

    // Local Components
    ViewInfoCard,
    StatusTimelineCard,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    KelengkapanData,
    Surat,
    Dokumen,
    BeritaAcara,
    // ViewDokumenLainnya,
    // ViewDokumenFoto,
    // UserViewUserTimelineCard,
    // UserViewUserPermissionsCard,

    // InvoiceList,
  },
  data() {
    return {
      // dataMasterJenisDokumenLainnya: null,
      // dataMasterJenisDokumenFoto: null,
      rowId: null,
    }
  },
  async beforeMount() {
    this.rowId = Number(this.$route.params.laporan_id)
  },
  mounted() {
    // this.reloadData()
  },
  methods: {
    async reloadData() {
      // const jenisDokumenLainnya = await getAllJenisDokumen(2)
      // this.dataMasterJenisDokumenLainnya = jenisDokumenLainnya.data

      // const jenisDokumenFoto = await getAllJenisDokumen(1)
      // this.dataMasterJenisDokumenFoto = jenisDokumenFoto.data
    },
  },
  setup() {
    const rowData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'laporan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('laporan/fetchData', { id: router.currentRoute.params.laporan_id })
      .then(response => { rowData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          rowData.value = undefined
        }
      })

    return {
      rowData,
    }
  },
}
</script>

<style>

</style>
